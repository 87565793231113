<template>
  <page-template>
    <main-block>
      <block-content>
        <nk-button type="primary" v-on:click="F_advisorsAndAvailability">Availability</nk-button>
        <card class="px-2 py-1 mb-3" no-padding>
          <div class="d-flex justify-between align-items-center fw-500 h6" :class="[lead_details.lead_type === 'Appointment' ? 'text-primary' : 'nk-text-dark']">
            <div>
              <nk-button type="" is-icon-only-button round v-on:click="$router.go(-1)" class="btn-trigger mr-3" :class="{'text-primary': lead_details.lead_type === 'Appointment'}">
                <nio-icon icon="ni-arrow-left"></nio-icon>
              </nk-button>
              <span>{{$t(lead_details.lead_type+' details')}}</span>
            </div>
            <nk-button type="primary" outline size="sm" v-if="lead_details.analytics_id" v-on:click="$router.push({name: 'adminLeadAnalytics', params:{id: lead_details.id}})">
              <nio-icon icon="ni-growth-fill mr-1"></nio-icon> {{$t('Analytics')}}
            </nk-button>
          </div>
        </card>
        <div v-if="leadDetailsError.length">
          <alert color="danger">{{leadDetailsError}}</alert>
        </div>
        <row v-else class="g-gs">
          <column default="12">
            <lead-followup-wizard v-if="lead_details.lead_type === 'Lead' && lead_details.followup_suggestion.id"
                                  :lead-data="lead_details.followup_suggestion" :advisor-lead-id="lead_details.AL_id"
                                  :lead-type="lead_details.lead_type"
                                  :star-rating="lead_details.rating"
                                  @on-lead-data-update="onLeadDataUpdate"
                                  @on-scroll-bottom="onScrollToBottom"
                                  :key="'lfw'+lead_details.id"></lead-followup-wizard>
            <appointment-feedback-wizard v-else-if="appointmentFeedbackDisplay"
                                         :lead-data="lead_details.followup_suggestion" :advisor-lead-id="lead_details.AL_id"
                                         :lead-type="lead_details.lead_type"
                                         :star-rating="lead_details.rating"
                                         :lead-is-assigned="lead_details.assigned_to.advisor_info.id"
                                         @on-scroll-bottom="onScrollToBottom"
                                         @on-lead-data-update="onLeadDataUpdate"></appointment-feedback-wizard>
          </column>
          <column lg="6" xxl="5">
            <row>
              <column :md="5">
                <b class="d-block text-primary">Lead #{{lead_details.lead_number}}</b>
                <badge v-if="lead_details.form_name" type="gray" style="font-size: 11px;">{{ $t('Form')+': '+ lead_details.form_name}}</badge>
                <star-rating
                    v-if="parseFloat(lead_details.assigned_to.advisor_info.rating) > 0"
                    :rating="lead_details.assigned_to.advisor_info.rating"
                    :increment="0.5" :star-size="12"
                    :show-rating="false"
                    :read-only="true"></star-rating>
              </column>
              <column :md="7" class="mt-2 mt-md-0">
                <div class="nk-text-muted">{{ $t('Received')}}:</div>
                <span class="form-control">{{ $t('on') }}: <b>{{ lead_details.received_date}}</b>, {{$t('at')}}: <b>{{ lead_details.received_time}}</b></span>
              </column>
            </row>
            <row class="mt-2">
              <column default="12">
                <ion-skeleton-text v-if="!lead_details.id" animated class="w-100" style="height: 100px;"></ion-skeleton-text>
                <lead-contact-details v-else :lead-data="lead_details"></lead-contact-details>
              </column>
            </row>
            <lead-data :lead_data="lead_details"></lead-data>
          </column>

          <column :lg="6" :xxl="7">
            <!-- show status if lead is not yet assigned to an advisor -->
            <card v-if="!lead_details.assigned_to.advisor_info.id">
              <span :style="{color: lead_details.status_color}">{{ lead_details.status_text}}</span>
            </card>
            <lead-mortgage-calculations :mortgage="lead_details.mortgage_calculations"></lead-mortgage-calculations>
          </column>

          <column xxl="7">
            <lead-on-map :lead-data="lead_details"></lead-on-map>
          </column>
          <column :xxl="5">
            <lead-new-note class="mb-4" @on-new-note-addition="onNewNoteAddition"></lead-new-note>
            <lead-notes-view :notes="lead_details.notes"></lead-notes-view>
          </column>

          <column :xxl="12">
            <lead-assignment-list v-if="lead_details.marketplace.id < 1 && !lead_details.assigned_to.advisor_info.id"
                                  :lead-data="lead_details"
                                  @on-lead-assigned="onLeadAssigned">

            </lead-assignment-list>
            <lead-assignment-info v-else-if="lead_details.assigned_to.advisor_info.id"
                                  :assignment-info="[lead_details.assigned_to.advisor_info]">

            </lead-assignment-info>
          </column>

        </row>
      </block-content>
    </main-block>
    <respond-to-lead-return
        v-if="returnResponse.isModalOpen"
        :is-modal-open="returnResponse.isModalOpen"
        :lead-data="returnResponse.leadData"
        @close-modal="returnResponse.isModalOpen=false"
        @status-change="returnStatusChanged"></respond-to-lead-return>
  </page-template>
</template>

<script>
import {
  IonSkeletonText,
  isPlatform, modalController, onIonViewWillEnter
} from '@ionic/vue';
import {computed, defineComponent, reactive, ref} from 'vue';
import MainBlock from "@core/layouts/main-block/MainBlock";
import BlockContent from "@core/layouts/main-block/components/BlockContent";
import { Row, Column } from '@core/layouts';
import { Card } from '@core/components/cards'

import "bootstrap"

import LeadData from "@/views/lead-details/LeadData";
import NioIcon from "@core/components/nio-icon/NioIcon";
import NkButton from "@core/components/button/NkButton";

import axios from "@/libs/axios";
import { useRoute } from "vue-router"
import { useRouter} from "vue-router"
import useDataChangeTracker from "@/store/data-change-tracker/dataChangeTracker"
import RespondToLeadReturn from "@/views/admin/Leads/components/RespondToLeadReturn";
import {useI18n} from "vue-i18n";
import StarRating from "vue-star-rating";
import useCommonFunc from "@core/comp-functions/common"
import Badge from "@core/components/badge/Badge";
import Alert from "@core/components/alert/Alert";
import LeadContactDetails from "@/views/admin/Leads/components/LeadContactDetails";
import LeadMortgageCalculations from "@/views/mortgage-calculations/LeadMortgageCalculations";
import LeadOnMap from "@/views/admin/Leads/components/LeadOnMap";
import LeadAssignmentList from "@/views/admin/Leads/components/LeadAssignmentList";
import LeadAssignmentInfo from "@/views/admin/Leads/components/LeadAssignmentInfo";
import LeadNotesView from "@/views/lead-details/LeadNotesView";
import LeadNewNote from "@/views/lead-details/LeadNewNote";
import LeadFollowupWizard from "@/views/lead-details/LeadFollowupWizard";
import AppointmentFeedbackWizard from "@/views/lead-details/AppointmentFeedbackWizard";
import AdvisorsAndAvailability from "@/views/admin/Leads/components/AdvisorsAndAvailability";
import PageTemplate from "@core/layouts/page/PageTemplate";

export default defineComponent({
  components: {
    PageTemplate,
    AppointmentFeedbackWizard,
    LeadFollowupWizard,
    LeadNewNote,
    LeadNotesView,
    LeadAssignmentInfo,
    LeadAssignmentList,
    LeadOnMap,
    LeadMortgageCalculations,
    LeadContactDetails,
    Alert,
    Badge,
    RespondToLeadReturn,
    NkButton,
    NioIcon,
    LeadData,
    Card,
    Row,
    Column,
    BlockContent,
    MainBlock,
    IonSkeletonText,
    StarRating,
  },
  setup() {

    const ion_content = ref()
    const i18n = useI18n()
    const isDesktop = isPlatform('desktop')
    const route  = useRoute()
    const router = useRouter()
    const { updateTracker } = useDataChangeTracker()
    const { updateObject } = useCommonFunc()

    // Lead related logic

    let lead_details    = reactive({
      analytics_id: '',
      assigned_to: {
        advisor_info: {
          assign_date: '',
          assign_date_unix: 0,
          assigned_by: 0,
          dp: '',
          email: '',
          first_name: '',
          id: false,
          initials: '',
          last_name: '',
          profile_id: 0,
          rating: 0,
          return_info:{},
          returned: 0,
          status_color: '',
          status_text: '',
          type: 'advisor',
        },
      },
      house_no: '',
      status_id: 0,
      event_date_iso: '',
      event_date: '',
      first_name: '',
      followup_logs: [],
      followup_suggestion:{
        assigned_date_ISO: '',
        attempt: 1,
        id: '',
        last_followup: {date: '', date_iso: '', time: '', time_name: '', result: 0},
        lead_number: '',
        next_followup: {type: "", date: "", future_date: ""},
        received_time: '',
        suggestions: {followup_time: ''},
      },
      id: 0,
      isAppointment: false,
      last_name: '',
      lead_type: null,
      marketplace: {
        id: 0,
        price: '',
        status: 0,
      },
      mortgage_calculations:{
        calculator: '',
        data: null,
      },
      notes: [],
      city: '',
      post_code: '',
      province: '',
      return_info: {
        admin_comment: '',
        explanation: '',
        reason: '',
        reason_id: 0,
        return_status: 0,
      },
      status_text: '',
      street: '',
    })
    let leadDetailsError = ref('')
    // Get lead details from the server
    const getLeadDetails = async () => {
      leadDetailsError.value = ''
      axios.get('/api/ap_agent/lead/details/'+route.params.id)
          .then( (resp) => {
            if(!resp.data.success){
              leadDetailsError.value = resp.data.message || i18n.t('errors.general_error')
            }
            else{
              updateObject(lead_details, resp.data.data)
            }
          })
          .catch( err => {
            leadDetailsError.value = err.response ? err.response.status+' '+err.response.statusText : i18n.t('errors.general_error')
          })
    }

    // Respond to lead return request
    const returnResponse = reactive({
      isModalOpen: false,
      leadData: lead_details.return_info,
      new_status: '',
      comment: '',
    })
    const returnStatusChanged = (d) => {
      returnResponse.leadData.return_status = parseInt(d.new_status)
      returnResponse.leadData.admin_comment = d.comment
      returnResponse.isModalOpen = false
      updateTracker('admin', ['returned_leads'])
    }

    onIonViewWillEnter(()=>{
      lead_details.id = null
      getLeadDetails()
    })

    const onLeadAssigned = (data)=>{
      updateObject(lead_details.assigned_to, data)
    }

    const onNewNoteAddition = (data) => {
      lead_details.notes.unshift(data)
    }

    const onLeadDataUpdate = (newData) => {
      lead_details.followup_suggestion.id = null
      setTimeout(()=> updateObject(lead_details, newData), 400)
    }

    const onScrollToBottom = ()=>{
      ion_content.value.$el.scrollToBottom(300);
    }

    const appointmentFeedbackDisplay = computed(()=>{

      // 5 = Had appointment, 8 = Appointment canceled by customer
      return lead_details.lead_type === 'Appointment' && !lead_details.assigned_to.advisor_info.id
          && lead_details.followup_suggestion.id
          && ![5, 8].includes( parseInt(lead_details.followup_suggestion.last_followup.result) )
    })

    const F_advisorsAndAvailability = async () => {
      const m = await modalController.create({
        component: AdvisorsAndAvailability,
        componentProps:{
          leadId: route.params.id
        },
        cssClass: 'advisors_and_availability_modal'+(isDesktop ? ' modal-web' : ''),
      })
      await m.present()
    }

    return {
      appointmentFeedbackDisplay,
      F_advisorsAndAvailability,
      ion_content,
      isDesktop,
      lead_details,
      leadDetailsError,
      onLeadDataUpdate,
      onNewNoteAddition,
      onScrollToBottom,
      returnResponse,
      returnStatusChanged,
      router,
      onLeadAssigned,
    }
  }
});
</script>

<template v-else>
  <span class="d-block nk-text-dark fw-500">{{leadData.first_name+' '+leadData.last_name}}</span>
  <div class="lh-n nk-text-muted mb-2">
    <div class="d-flex align-items-center">
      <nio-icon icon="ni-call" class="mr-1"></nio-icon> {{leadData.phone_no}}
    </div>
    <div class="d-flex align-items-center">
      <nio-icon icon="ni-mail" class="mr-1"></nio-icon> {{leadData.email}}
    </div>
  </div>
  <div class="nk-text-muted">{{ leadData.house_no+', '+leadData.street }}<br/>{{ leadData.post_code}}, {{leadData.city+' '+leadData.province}}</div>
</template>

<script>
export default {
  props:{
    leadData: Object,
  }
}
</script>

<template>
  <card no-padding stretch>
    <accordion id="lead_map" class="is-separate bg-transparent">
      <accordion-item class="light-shadow mb-0" id="acc_lead_map">
        <accordion-item-head data-target="#lead_map_content" :collapsed="!isDesktop">
          <div class="d-flex align-items-center">
            <nio-icon icon="ni-map" class="mr-2 text-primary" style="font-size: 1.5rem"></nio-icon>
            <div class="flex-fill">{{ $t('View on map') }}</div>
          </div>
        </accordion-item-head>
        <accordion-item-body id="lead_map_content" data-parent="#acc_lead_map" no-padding class="border-top" :collapsed="!isDesktop">
          <div class="p-4">
            <row class="nk-text-dark">
              <column :md="5">
                <div class="font-weight-bold">{{ leadData.house_no+', '+leadData.street }}</div>
                <div class="nk-text-muted lh-n">{{ leadData.post_code }}, {{ leadData.city }} {{ leadData.province }}</div>
              </column>
            </row>
          </div>
          <lead-location-map
              map-type="roadmap"
              :start="leadData.post_code+' '+leadData.house_no+','+leadData.city"
              end="" height="400px"></lead-location-map>
        </accordion-item-body>
      </accordion-item>
    </accordion>
  </card>
</template>

<script>
import Card from "@core/components/cards/Card";
import {Accordion, AccordionItem, AccordionItemBody, AccordionItemHead} from "@core/components/accordion";
import {Column, Row} from "@core/layouts";
import LeadLocationMap from "@/views/lead-details/LeadLocationMap";
import {isPlatform} from "@ionic/vue";
export default {
  components: {LeadLocationMap, Column, Row, AccordionItemBody, AccordionItemHead, AccordionItem, Accordion, Card},
  props:{
    leadData: Object,
  },
  setup(){
    const isDesktop = isPlatform('desktop')
    return{
      isDesktop,
    }
  }
}
</script>
